import mock from './mock'

/* eslint-disable import/extensions */

// JWT
import './jwt'

// IT
import './data/apps/enterprise'
import './data/apps/user'
import './data/apps/organization'
import './data/apps/role'
import './data/apps/dictionary'
import './data/apps/product'
import './data/apps/datamap'
import './data/apps/cpm'
import './data/apps/pia'
import './data/apps/cookies'
import './data/apps/lib-law'
import './data/apps/lib-case'
import './data/apps/lib-survey'
import './data/apps/lib-template'
import './data/apps/lib-flow'
import './data/apps/lib-dictionary'
import './data/apps/ent-policy'
import './data/apps/ent-statement'
import './data/apps/training-course'

// // Table
// import './data/extensions/good-table'
// import './data/extensions/auto-suggest'
// import './data/card/card-statistics'
// import './data/card/card-analytics'

// Apps
// import './data/apps/calendar'
// import './data/apps/user'
// import './data/apps/email'
// import './data/apps/invoice'
// import './data/apps/todo'
// import './data/apps/chat'
// import './data/apps/eCommerce'

// dashboard
// import './data/dashboard/analytics'
// import './data/dashboard/ecommerce'

// pages
// import './data/pages/faq-data'
// import './data/pages/knowledge-base'
// import './data/pages/pricing-data'
// import './data/pages/account-setting'
// import './data/pages/profile-data'
// import './data/pages/blog'
/* eslint-enable import/extensions */

mock.onAny().passThrough() // forwards the matched request over network
