export default [
  {
    path: '/consent',
    name: 'consent',
    component: () => import('@/views/consent/Consent.vue'),
    meta: {
      pageTitle: '用户同意管理',
      breadcrumb: [
        {
          text: '用户同意管理',
          active: true,
        },
      ],
    },
  },

]