export default [
  {
    path: '/statistics',
    name: 'statistics',
    component: () => import('@/views/statistics/Statistics.vue'),
    meta: {
      resource: 'statistics',
      pageTitle: '运营分析',
      breadcrumb: [
        {
          text: '统计分析',
          active: true,
        },
      ],
    },
  },
  {
    path: '/largescreen',
    name: 'largescreen',
    component: () => import('@/views/statistics/LargeScreen.vue'),
    meta: {
      resource: 'largescreen',
      pageTitle: '运营分析',
      breadcrumb: [
        {
          text: '大屏展示',
          active: true,
        },
      ],
    },
  },

]