import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
    list: [
        {
            id: 1,
            name: '小鹏汽车门店装摄像头擅自采集43万张人脸被罚',
            industry: '智能车联',
            enterprise:'上海小鹏汽车销售服务有限公司',
            time:'2022-02-01',
            description: '处罚事由为，当事人购买具有人脸识别功能的摄像设备22台安装在旗下门店，以此统计进店人数并分析男女比例、年龄等。 \r\n 今年1月至6月，共采集上传人脸照片431623张。该行为未经得消费者同意，也无明示、告知消费者收集、使用目的，违反消费者权益保护法。 ',
            violation:'个保法 第13条\r\n民法典 第111条\r\n网络安全法 第42条\r\n中华人民共和国消费者权益保护法 第29条\r\n',
            basis:'消费者权益保护法：第五十六条 经营者有下列情形之一，除承担相应的民事责任外，其他有关法律、法规对处罚机关和处罚方式有规定的，依照法律、法规的规定执行；法律、法规未作规定的，由工商行政管理部门或者其他有关行政部门责令改正，可以根据情节单处或者并处警告、没收违法所得、处以违法所得一倍以上十倍以下的罚款，没有违法所得的，处以五十万元以下的罚款；情节严重的，责令停业整顿、吊销营业执照：\r\n（一）提供的商品或者服务不符合保障人身、财产安全要求的；\r\n消费者权益保护法：第二十九条\r\n行政处罚法：第三十二条',
            unit: '徐汇区市场监督管理局',
            type:'行政处罚',
            punishment:'罚款10万元',
            published:'2020-02-01',
        },
        {
            id: 2,
            name: '99款App因数据安全下架',
            industry: '互联网',
            enterprise:'上海小鹏汽车销售服务有限公司',
            time:'2021-04-23',
            description: '2021年4月23日，工信部部向社会通报了93家存在侵害用户权益行为APP企业的名单。截至目前，经第三方检测机构核查复检，尚有39款APP未按照工信要求完成整改\r\n 今年1月至6月，共采集上传人脸照片431623张。该行为未经得消费者同意，也无明示、告知消费者收集、使用目的，违反消费者权益保护法。 ',
            violation:'个保法 第13条\r\n民法典 第111条\r\n网络安全法 第42条\r\n中华人民共和国消费者权益保护法 第29条\r\n',
            basis:'消费者权益保护法：第五十六条 经营者有下列情形之一，除承担相应的民事责任外，其他有关法律、法规对处罚机关和处罚方式有规定的，依照法律、法规的规定执行；法律、法规未作规定的，由工商行政管理部门或者其他有关行政部门责令改正，可以根据情节单处或者并处警告、没收违法所得、处以违法所得一倍以上十倍以下的罚款，没有违法所得的，处以五十万元以下的罚款；情节严重的，责令停业整顿、吊销营业执照：\r\n（一）提供的商品或者服务不符合保障人身、财产安全要求的；\r\n消费者权益保护法：第二十九条\r\n行政处罚法：第三十二条',
            unit: '徐汇区市场监督管理局',
            type:'行政处罚',
            punishment:'罚款10万元',
            published:'2021-06-01',
        },
        {
            id: 3,
            name: '十多家保险经纪公司个人数据泄漏',
            industry: '证劵金融',
            enterprise:'上海小鹏汽车销售服务有限公司',
            time:'2022-03-01',
            description: '十多家保险经纪公司个人数据泄漏等。 \r\n 今年1月至6月，共采集上传人脸照片431623张。该行为未经得消费者同意，也无明示、告知消费者收集、使用目的，违反消费者权益保护法。 ',
            violation:'个保法 第13条\r\n民法典 第111条\r\n网络安全法 第42条\r\n中华人民共和国消费者权益保护法 第29条\r\n',
            basis:'消费者权益保护法：第五十六条 经营者有下列情形之一，除承担相应的民事责任外，其他有关法律、法规对处罚机关和处罚方式有规定的，依照法律、法规的规定执行；法律、法规未作规定的，由工商行政管理部门或者其他有关行政部门责令改正，可以根据情节单处或者并处警告、没收违法所得、处以违法所得一倍以上十倍以下的罚款，没有违法所得的，处以五十万元以下的罚款；情节严重的，责令停业整顿、吊销营业执照：\r\n（一）提供的商品或者服务不符合保障人身、财产安全要求的；\r\n消费者权益保护法：第二十九条\r\n行政处罚法：第三十二条',
            unit: '徐汇区市场监督管理局',
            type:'行政处罚',
            punishment:'罚款10万元',
            published:'2020-08-01',
        },
    ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return objects
// ------------------------------------------------
mock.onGet('/api/lib-case').reply(config => {
    // eslint-disable-next-line object-curly-newline
    const {
        q = '',
        perPage = 10,
        page = 1,
        sortBy = 'id',
        sortDesc = false,
        status = null,
    } = config.params
    /* eslint-enable */

    const queryLowered = q.toLowerCase()
    const filteredData = data.list.filter(
        item =>
            /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
            (item.name.toLowerCase().includes(queryLowered) ||
                item.description.toLowerCase().includes(queryLowered) )
    )
    /* eslint-enable  */

    const sortedData = filteredData.sort(sortCompare(sortBy))
    if (sortDesc) sortedData.reverse()

    return [
        200,
        {
            success: 0,
            list: paginateArray(sortedData, perPage, page),
            total: filteredData.length,
        },
    ]
})

// ------------------------------------------------
// POST: Add new 
// ------------------------------------------------
mock.onPost('/api/lib-case').reply(config => {
    // Get event from post data
    const { item } = JSON.parse(config.data)

    // Assign Status
    item.status = 'active'

    const { length } = data.list
    let lastIndex = 0
    if (length) {
        lastIndex = data.item[length - 1].id
    }
    item.id = lastIndex + 1

    data.list.push(item)

    return [201, { success: 0, item }]
})

// ------------------------------------------------
// PATCH: update
// ------------------------------------------------
mock.onPatch(/\/api\/lib-case\/\d+/).reply(config => {
    // Get event id from URL
    let id = config.url.substring(config.url.lastIndexOf('/') + 1)

    // Get event from post data
    let { course } = JSON.parse(config.data)

    // Convert Id to number
    id = Number(id)

    const idx = data.list.findIndex(e => e.id === id)

    // UPDATE
    data.list.splice(idx, 1, course)
    course = data.list[idx]

    return [200, { success: 0, course }]
})

// ------------------------------------------------
// GET: Return Single 
// ------------------------------------------------
mock.onGet(/\/api\/lib-case\/\d+/).reply(config => {
    // Get event id from URL
    let id = config.url.substring(config.url.lastIndexOf('/') + 1)

    // Convert Id to number
    id = Number(id)

    const idx = data.list.findIndex(e => e.id === id)
    const item = data.list[idx]

    if (item) return [200, { sucess: 0, item }]
    return [404]
})

// ------------------------------------------------
// GET: delete Single 
// ------------------------------------------------
mock.onDelete(/\/api\/lib-case\/\d+/).reply(config => {
    // Get event id from URL
    let id = config.url.substring(config.url.lastIndexOf('/') + 1)

    // Convert Id to number
    id = Number(id)

    const idx = data.list.findIndex(e => e.id === id)
    data.list.splice(idx, 1);

    return [200, { sucess: 0 }]
})

