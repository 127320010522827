export default [
  {
    path: '/teamplate',
    name: 'template',
    component: () => import('@/views/template/Template.vue'),
    meta: {
      pageTitle: '模板库',
      breadcrumb: [
        {
          text: '模板库',
          active: true,
        },
      ],
    },
  },

]