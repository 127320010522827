export default [
  {
    path: '/compliance-list',
    name: 'compliance-list',
    component: () => import('@/views/compliance/ComplianceList.vue'),
    meta: {
      resource: 'compliance',
      pageTitle: '合规评估',
      breadcrumb: [
        {
          text: '法律合规管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/compliance-process',
    name: 'compliance-process',
    component: () => import('@/views/compliance/ComplianceProcess.vue'),
    meta: {
      resource: 'compliance',
      pageTitle: '合规评估',
      breadcrumb: [
        {
          text: '合规问卷填报',
          active: true,
        },
      ],
    },
  },

]
