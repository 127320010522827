import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  enterprises: [
    {
      id: 1,
      code: 'S01',
      name: '北京精一国际',
      overview: '',
      compliances: ['GDPR','PIPL'],
      contactName: '王飞乐',
      contactPhone: '18912345678',
      contactEmail: 'wangfl@jingyi.com',
      industry: '互联网',
      scale: '100-1000',
      adminId: 1,
      createdAt: '2022-1-1T08:05:00.000+08.000',
      createdBy: '',
      attachments: [],
      status: 'active',
    },
    {
      id: 2,
      code: 'S02',
      name: '上海大世界贸易',
      description: '',
      compliances: ['GDPR','CCPA','PIPL'],
      contactName: '李琦',
      contactPhone: '18912345679',
      contactEmail: 'liqi@dashijie.com',
      industry: '贸易',
      scale: '1-100',
      adminId: 2,
      createdAt: '2022-1-1T08:05:00.000+08.000',
      createdBy: '',
      attachments: [],
      status: 'active',
    },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------
mock.onGet('/api/enterprise').reply(config => {
  
  // eslint-disable-next-line object-curly-newline
  const {
    industry = '',
    compliance = '',
    q = '',
    perPage = 10,
    page = 1,
    sortBy = 'id',
    sortDesc = false,
    status = null,
  } = config.params
  /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.enterprises.filter(
    enterprise =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      (enterprise.name.toLowerCase().includes(queryLowered) || 
      enterprise.contactName.toLowerCase().includes(queryLowered) || 
      enterprise.contactPhone.toLowerCase().includes(queryLowered)  || 
      enterprise.contactEmail.toLowerCase().includes(queryLowered) ) &&
      enterprise.industry.includes(industry) &&
      (enterprise.compliances.includes(compliance) || !compliance)
  )
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      success: 0,      
      enterprises: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})

// ------------------------------------------------
// POST: Add new 
// ------------------------------------------------
mock.onPost('/api/enterprise').reply(config => {
  // Get event from post data
  const { enterprise } = JSON.parse(config.data)

  // Assign Status
  enterprise.status = 'active'

  const { length } = data.enterprises
  let lastIndex = 0
  if (length) {
    lastIndex = data.enterprise[length - 1].id
  }
  enterprise.id = lastIndex + 1

  data.enterprises.push(enterprise)

  return [201, { success: 0, enterprise }]
})

// ------------------------------------------------
// PATCH: update
// ------------------------------------------------
mock.onPatch(/\/api\/enterprise\/\d+/).reply(config => {
  // Get event id from URL
  let id = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Get event from post data
  let { enterprise } = JSON.parse(config.data)

  // Convert Id to number
  id = Number(id)

  const idx = data.enterprises.findIndex(e => e.id === id)

  // UPDATE
  data.enterprises.splice(idx, 1, enterprise)
  enterprise = data.enterprises[idx]

  return [200, { success: 0, enterprise }]
})

// ------------------------------------------------
// GET: Return Single 
// ------------------------------------------------
mock.onGet(/\/api\/enterprise\/\d+/).reply(config => {
  // Get event id from URL
  let id = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  id = Number(id)

  const idx = data.enterprises.findIndex(e => e.id === id)
  const enterprise = data.enterprises[idx]

  if (enterprise) return [200, {sucess:0, enterprise}]
  return [404]
})

// ------------------------------------------------
// GET: delete Single 
// ------------------------------------------------
mock.onDelete(/\/api\/enterprise\/\d+/).reply(config => {
  // Get event id from URL
  let id = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  id = Number(id)

  const idx = data.enterprises.findIndex(e => e.id === id)
  data.enterprises.splice(idx, 1);

  return [200, {sucess:0}]
})

