export default [
  {
    path: '/labrary/law',
    name: 'library-law',
    component: () => import('@/views/library/law/LibLaw.vue'),
    meta: {
      pageTitle: '知识库',
      resource: 'library-law',
      action: 'manage',
      breadcrumb: [
        {
          text: '法律库',
          active: true,
        },
      ],
    },
  },

  {
    path: '/labrary/law/:id',
    name: 'library-law-detail',
    component: () => import('@/views/library/law/LibLawDetail.vue'),
    meta: {
      pageTitle: '知识库',
      resource: 'library-law',
      action: 'manage',
      breadcrumb: [
        {
          text: '法律库',
          to: '/labrary/law',
        },
        {
          text: '详情',
          active: true,
        },
      ],
    },
  },

  {
    path: '/labrary/case',
    name: 'library-case',
    component: () => import('@/views/library/case/LibCase.vue'),
    meta: {
      pageTitle: '知识库',
      resource: 'library-case',
      action: 'manage',
      breadcrumb: [
        {
          text: '案例库',
          active: true,
        },
      ],
    },
  },
  {
    path: '/labrary/case/:id/edit',
    name: 'library-case-edit',
    component: () => import('@/views/library/case/LibCaseEdit.vue'),
    meta: {
      pageTitle: '知识库',
      resource: 'library-case',
      action: 'manage',
      breadcrumb: [
        {
          text: '案例库',
          to: '/labrary/case',
        },
        {
          text: '详情',
          active: true,
        },
      ],
    },
  },

  {
    path: '/labrary/survey',
    name: 'library-survey',
    component: () => import('@/views/library/survey/LibSurvey.vue'),
    meta: {
      pageTitle: '知识库',
      resource: 'library-survey',
      action: 'manage',
      breadcrumb: [
        {
          text: '问卷库',
          active: true,
        },
      ],
    },
  },
  {
    path: '/labrary/survey/:id/edit',
    name: 'library-survey-edit',
    component: () => import('@/views/library/survey/LibSurveyEdit.vue'),
    meta: {
      pageTitle: '知识库',
      resource: 'library-survey',
      action: 'manage',
      breadcrumb: [
        {
          text: '问卷库',
          to: '/labrary/survey',
        },
        {
          text: '编辑',
          active: true,
        },
      ],
    },
  },

  {
    path: '/labrary/template',
    name: 'library-template',
    component: () => import('@/views/library/template/LibTemplate.vue'),
    meta: {
      pageTitle: '知识库',
      resource: 'library-template',
      action: 'manage',
      breadcrumb: [
        {
          text: '模板库',
          active: true,
        },
      ],
    },
  },

  {
    path: '/labrary/flow',
    name: 'library-flow',
    component: () => import('@/views/library/flow/LibFlow.vue'),
    meta: {
      pageTitle: '知识库',
      resource: 'library-flow',
      action: 'manage',
      breadcrumb: [
        {
          text: '流程库',
          active: true,
        },
      ],
    },
  },

  {
    path: '/labrary/flow/:id/edit',
    name: 'library-flow-edit',
    component: () => import('@/views/library/flow/LibFlowEdit.vue'),
    meta: {
      pageTitle: '知识库',
      resource: 'library-flow',
      action: 'manage',
      breadcrumb: [
        {
          text: '流程库',
          to: '/labrary/flow',
        },
        {
          text: '编辑',
          active: true,
        },
      ],
    },
  },

  {
    path: '/labrary/dictionary',
    name: 'library-dictionary',
    component: () => import('@/views/library/dictionary/LibDictionary.vue'),
    meta: {
      pageTitle: '知识库',
      resource: 'library-dictionary',
      action: 'manage',
      breadcrumb: [
        {
          text: '数据字典',
          active: true,
        },
      ],
    },
  },

]