import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  datamaps: [
    {
      index: 1,
      fieldnameC: '个人姓名',
      fieldnameE: 'personal-name',
      catalog: '个人基本资料',
      level: '一般个人数据',
      note: '',
    },
    {
      index: 2,
      fieldnameC: '生日',
      fieldnameE: 'birthday',
      catalog: '个人基本资料',
      level: '一般个人数据',
      note: '',
    },
    {
      index: 3,
      fieldnameC: '性别',
      fieldnameE: 'sex',
      catalog: '个人基本资料',
      level: '一般个人数据',
      note: '',
    },
    {
      index: 4,
      fieldnameC: '民族',
      fieldnameE: 'ethnicity',
      catalog: '个人基本资料',
      level: '一般个人数据',
      note: '',
    },
    {
      index: 5,
      fieldnameC: '国籍',
      fieldnameE: 'nationality',
      catalog: '个人基本资料',
      level: '一般个人数据',
      note: '',
    },
    {
      index: 6,
      fieldnameC: '家庭关系',
      fieldnameE: 'family-relationship',
      catalog: '个人基本资料',
      level: '一般个人数据',
      note: '',
    },
    {
      index: 7,
      fieldnameC: '住址',
      fieldnameE: 'address',
      catalog: '个人基本资料',
      level: '一般个人数据',
      note: '',
    },
    {
      index: 8,
      fieldnameC: '个人电话号码',
      fieldnameE: 'personal-phone-number',
      catalog: '个人基本资料',
      level: '一般个人数据',
      note: '',
    },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return list
// ------------------------------------------------
mock.onGet('/api/datamap/list').reply(config => {
  // eslint-disable-next-line object-curly-newline
  // const { q = '', perPage = 10, page = 1, sortBy = 'id', sortDesc = false, status = null } = config.params
  /* eslint-enable */

  // const queryLowered = q.toLowerCase()
  // const filteredData = data.datamaps.filter(
  //   item =>
  //     /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
  //     (item.client.companyEmail.toLowerCase().includes(queryLowered) ||
  //       item.client.name.toLowerCase().includes(queryLowered)) &&
  //     item.itemStatus === (status || item.itemStatus),
  // )
  /* eslint-enable  */

  // const sortedData = filteredData.sort(sortCompare(sortBy))
  // if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      // datamaps: paginateArray(sortedData, perPage, page),
      datamaps: data.datamaps,
      total: data.datamaps.length,
    },
  ]
})

// ------------------------------------------------
// GET: Return Single 
// ------------------------------------------------
mock.onGet(/\/api\/datamap\/\d+/).reply(config => {
  // Get event id from URL
  let id = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  id = Number(id)

  const index = data.datamaps.findIndex(e => e.id === id)
  const item = data.datamaps[index]
  const responseData = {
    item,
  }

  if (invoice) return [200, responseData]
  return [404]
})