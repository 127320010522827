export default [
  {
    path: '/enterprise/policy',
    name: 'enterprise-policy',
    component: () => import('@/views/enterprise/policy/Policy.vue'),
    meta: {
      resource: 'enterprise',
      action: 'manage',
      pageTitle: '企业信息',
      breadcrumb: [
        {
          text: '隐私政策',
          active: true,
        },
      ],
    },
  },
  
  {
    path: '/enterprise/policy/:id',
    name: 'enterprise-policy-view',
    component: () => import('@/views/enterprise/policy/PolicyView.vue'),
    meta: {
      pageTitle: '企业信息',
      resource: 'enterprise',
      action: 'manage',
      breadcrumb: [
        {
          text: '隐私政策',
          to: '/enterprise/policy',
        },
        {
          text: '详情',
          active: true,
        },
      ],
    },
  },
  
  {
    path: '/enterprise/organization',
    name: 'enterprise-organization',
    component: () => import('@/views/enterprise/organization/Organization.vue'),
    meta: {
      resource: 'enterprise',
      pageTitle: '企业信息',
      breadcrumb: [
        {
          text: '管理团队',
          active: true,
        },
      ],
    },
  },
  
  {
    path: '/enterprise/organization/:id',
    name: 'enterprise-organization-view',
    component: () => import('@/views/enterprise/organization/OrganizationView.vue'),
    meta: {
      pageTitle: '企业信息',
      resource: 'enterprise',
      action: 'manage',
      breadcrumb: [
        {
          text: '管理团队',
          to: '/enterprise/organization',
        },
        {
          text: '详情',
          active: true,
        },
      ],
    },
  },
  

  {
    path: '/enterprise/statement',
    name: 'enterprise-statement',
    component: () => import('@/views/enterprise/statement/Statement.vue'),
    meta: {
      resource: 'enterprise',
      pageTitle: '企业信息',
      breadcrumb: [
        {
          text: '隐私声明',
          active: true,
        },
      ],
    },
  },
  {
    path: '/enterprise/statement/:id',
    name: 'enterprise-statement-detail',
    component: () => import('@/views/enterprise/statement/StatementView.vue'),
    meta: {
      pageTitle: '企业信息',
      resource: 'enterprise',
      action: 'manage',
      breadcrumb: [
        {
          text: '隐私声明',
          to: '/enterprise/statement',
        },
        {
          text: '详情',
          active: true,
        },
      ],
    },
  },

]