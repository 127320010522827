import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
    list: [
        {  
            id: 1,
            name: 'GDPR基本原则',
            industry: 'GDPR',
            enterprise:'',
            time:'2022-01-01',
            description: '1. 合法，公平，透明三原则：与数据主体个人相关的数据信息应当以合法，公正，透明方式处理；2. 数据收集应当有明确的目的：个人信息收集应当目的特定，明确和合法，任何与上述目的不符合的方式将不能继续处理数据；3. 数据收集的最小化原则：个人数据收集应当仅仅限于一切与数据处理目的相关的必要的数据；4. 准确性：个人数据应当准确，如果需要尽可能保持最新的数据。',
            violation:'',
            basis:'',
            unit: '',
            type:'',
            punishment:'',
            published:'2022-01-01',
        },
        {
            id: 2,
            name: '数据主体的权利',
            industry: 'GDPR',
            enterprise:'',
            time:'2022-01-01',
            description: '1. 信息透明度和信息机制：数据处理者或控制者必须保证数据主体行使权利的透明度、交流和模式，也就是让用户知道你在收集那些数据，为什么收据数据，用于何种目的，另外也需要让用户可以随时对自己的数据进行控制。    2. 数据访问权，控制者应当保证数据主体可以随时访问自己的数据。',
            violation:'',
            basis:'',
            unit: '',
            type:'',
            punishment:'',
            published:'2022-01-01',
        },
        {
            id: 3,
            name: '控制者或数据处理者的义务',
            industry: 'GDPR',
            enterprise:'',
            time:'2022-01-01',
            description: '控制者应该在确定处理手段和在处理的同时，实施适当的技术和组织措施，如匿名化，即目的是实施数据保护原则，如数据最小化，以有效的方式，在处理时实施必要的保障措施，以符合法律要求，保护数据主体的权利。',
            violation:'',
            basis:'',
            unit: '',
            type:'',
            punishment:'',
            published:'2022-01-01',
        },
        {
            id: 4,
            name: '转移个人数据（欧盟）到第三国或国际组织',
            industry: 'GDPR',
            enterprise:'',
            time:'2022-01-01',
            description: '1. 数据转移到第三国或国际组织，第三国或国际组织应当对用户数据隐私和安全提供足够的保护。 2.  欧盟数据委员会会在官网及欧洲联盟公报上公布第三国或国际组织是否能够对个人数据提供足够的保护。 3.   数据处理者或控制者拟向第三国或国际组织转移数据，而第三国或国际组织没有列入符合欧盟个人数据保护要求的，通过提供适当的安全措施，以及在可强制执行的数据主体权利和对数据主题的有效法律补救措施时就绪的条件下，一个控制者或处理者可以将个人数据转移到第三个国家或国际组织。',
            violation:'',
            basis:'',
            unit: '',
            type:'',
            punishment:'',
            published:'2022-01-01',
        },
        {
            id: 5,
            name: '评估措施，数据安全与数据泄露通知义务',
            industry: 'GDPR',
            enterprise:'',
            time:'2022-01-01',
            description: '1. GDPR要求所有公司或组织实施最广泛的措施降低违反GDPR的风险，并保证合规处理数据。 2. 这包括可评估的措施比如数据隐私影响评估，审计，政策检查，活动记录，任命数据官等一些列可衡量的措施。 3. 公司或组织需要部署人员和财力来准备合规工作。 4. Privacy Impact Assessments (PIAs) 开展隐私影响评估工作，需要形成书面文档。 5.    记录数据处理工作，形成工作文档备查。',
            violation:'',
            basis:'',
            unit: '',
            type:'',
            punishment:'',
            published:'2022-01-01',
        },
        {
            id: 6,
            name: '数据泄露通知义务',
            industry: 'GDPR',
            enterprise:'',
            time:'2022-01-01',
            description: '1. 数据控制者和处理者应履行数据泄露通知义务； 2. 数据处理者必须把数据泄露通知给数据控制者；3. 数据控制者必须把数据泄露通知给监管当局； 4.   数据泄露必须72小时通知监管当局，并根据情况通知到数据泄露的用户；5. 必须确保有现成的数据泄露发现，调查，内部报告机制（内部要有规范性文档，流程等）；6.  数据泄露必须保存记录，无论是否有报告义务。',
            violation:'',
            basis:'',
            unit: '',
            type:'',
            punishment:'',
            published:'2022-01-01',
        },
        {
            id: 7,
            name: 'GDPR 个人数据定义',
            industry: 'GDPR',
            enterprise:'',
            time:'2022-01-01',
            description: '“个人数据”是指任何指向一个已识别或可识别的自然人（“数据主体”）的信息。该可识别的自然人能够被直接或间接地识别，尤其是通过参照诸如姓名、身份证号码、定位数据、在线身份识别这类标识，或者是通过参照针对该自然人一个或多个如物理、生理、遗传、心理、经济、文化或社会身份的要素。个人信息实例：\r\n· a name and surname; （名字）\r\n· a home address; 地址\r\n· an email address such as name.surname@company.com; 邮箱\r\n· an identification card number; 身份证号\r\n· location data (for example the location data function on a mobile phone)*; 地理位置\r\n· an Internet Protocol (IP) address; IP地址\r\n· a cookie ID*; COOKIE ID\r\n· the advertising identifier of your phone; 广告ID\r\n',
            violation:'',
            basis:'',
            unit: '',
            type:'',
            punishment:'',
            published:'2022-01-01',
        },
        {
            id: 8,
            name: 'GDPR 形式合规',
            industry: 'GDPR',
            enterprise:'',
            time:'2022-01-01',
            description: '• 按照GDPR的基本原则来收集数据；• 加入明确授权和撤回按钮，让用户可随时撤回授权，随时访问数据，更正数据，删除，注销账户等。以GDPR数据主体权利为基准保证产品符合规定。网站或APP可加入隐私及数据控制面板，让用户可以随时访问，纠正，删除自己的数据等；• 所有用户授权与同意，应留存证据，可以以电子形式保存（截图，电子合同等）；•  保证产品中的SDK及第三方合作伙伴数据收集也符合GDPR规定； • 产品隐私及数据安全技术措施处理；• 保证产品数据存储安全；',
            violation:'',
            basis:'',
            unit: '',
            type:'',
            punishment:'',
            published:'2022-01-01',
        },
        {
            id: 9,
            name: 'GDPR 产品合规',
            industry: 'GDPR',
            enterprise:'',
            time:'2022-01-01',
            description: '1. 起草一份符合欧盟要求的隐私协议及服务条款，其中隐私协议应该列出以下内容； 2. 收集和存储了那些数据；3. 收集数据的目的，尽可能和收集数据的类型相匹配，逐条列出具体明确的可分割的目的； 4. 数据处理的方式，是否会追踪用户的数据，是否会采用cookie等类似的用户画像技术辨识客户；5. 披露合作伙伴和第三方；6.   数据安全保护措施；7. 是否转移欧盟用户到境外，如何保证数据安全；8. 保证GDPR规定的用户权利，访问权，更正权，遗忘权，撤销权等，并在隐私协议中提供实现方式，如果无法自动实现，可以让用户提交反馈表，核实后一个月内手动实现。',
            violation:'',
            basis:'',
            unit: '',
            type:'',
            punishment:'',
            published:'2022-01-01',
        },
        ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return objects
// ------------------------------------------------
mock.onGet('/api/lib-dict').reply(config => {
    // eslint-disable-next-line object-curly-newline
    const {
        q = '',
        perPage = 10,
        page = 1,
        sortBy = 'id',
        sortDesc = false,
        status = null,
    } = config.params
    /* eslint-enable */

    const queryLowered = q.toLowerCase()
    const filteredData = data.list.filter(
        item =>
            /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
            (item.name.toLowerCase().includes(queryLowered) ||
                item.description.toLowerCase().includes(queryLowered) )
    )
    /* eslint-enable  */

    const sortedData = filteredData.sort(sortCompare(sortBy))
    if (sortDesc) sortedData.reverse()

    return [
        200,
        {
            success: 0,
            list: paginateArray(sortedData, perPage, page),
            total: filteredData.length,
        },
    ]
})

// ------------------------------------------------
// POST: Add new 
// ------------------------------------------------
mock.onPost('/api/lib-dict').reply(config => {
    // Get event from post data
    const { item } = JSON.parse(config.data)

    // Assign Status
    item.status = 'active'

    const { length } = data.list
    let lastIndex = 0
    if (length) {
        lastIndex = data.item[length - 1].id
    }
    item.id = lastIndex + 1

    data.list.push(item)

    return [201, { success: 0, item }]
})

// ------------------------------------------------
// PATCH: update
// ------------------------------------------------
mock.onPatch(/\/api\/lib-dict\/\d+/).reply(config => {
    // Get event id from URL
    let id = config.url.substring(config.url.lastIndexOf('/') + 1)

    // Get event from post data
    let { course } = JSON.parse(config.data)

    // Convert Id to number
    id = Number(id)

    const idx = data.list.findIndex(e => e.id === id)

    // UPDATE
    data.list.splice(idx, 1, course)
    course = data.list[idx]

    return [200, { success: 0, course }]
})

// ------------------------------------------------
// GET: Return Single 
// ------------------------------------------------
mock.onGet(/\/api\/lib-dict\/\d+/).reply(config => {
    // Get event id from URL
    let id = config.url.substring(config.url.lastIndexOf('/') + 1)

    // Convert Id to number
    id = Number(id)

    const idx = data.list.findIndex(e => e.id === id)
    const item = data.list[idx]

    if (item) return [200, { sucess: 0, item }]
    return [404]
})

// ------------------------------------------------
// GET: delete Single 
// ------------------------------------------------
mock.onDelete(/\/api\/lib-dict\/\d+/).reply(config => {
    // Get event id from URL
    let id = config.url.substring(config.url.lastIndexOf('/') + 1)

    // Convert Id to number
    id = Number(id)

    const idx = data.list.findIndex(e => e.id === id)
    data.list.splice(idx, 1);

    return [200, { sucess: 0 }]
})

