import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
    list: [
        {
            id: 1,
            name: '个保法第一课',
            industry: 'PIPL',
            enterprise:'',
            time:'2022-02-01',
            description: '入门介绍材料',
            violation:'',
            basis:'/assets/video/first-class.mp4',
            unit: '',
            type:'',
            punishment:'',
            published:'2020-02-01',
        },
        {
            id: 2,
            name: '个保法第二课',
            industry: 'PIPL',
            enterprise:'',
            time:'2022-02-01',
            description: '法规条款解读',
            violation:'',
            basis:'/assets/video/first-class.mp4',
            unit: '',
            type:'',
            punishment:'',
            published:'2020-02-01',
        },
        {
            id: 3,
            name: '个保法第三课',
            industry: 'PIPL',
            enterprise:'',
            time:'2022-02-01',
            description: '合规实践应用',
            violation:'',
            basis:'/assets/video/first-class.mp4',
            unit: '',
            type:'',
            punishment:'',
            published:'2020-02-01',
        },
        {
            id: 4,
            name: 'GDPR条款解读',
            industry: 'GDPR',
            enterprise:'',
            time:'2022-02-01',
            description: '法规条款解读',
            violation:'',
            basis:'/assets/video/first-class.mp4',
            unit: '',
            type:'',
            punishment:'',
            published:'2020-02-01',
        },
        {
            id: 5,
            name: 'CCPA条款解读',
            industry: 'CCPA',
            enterprise:'',
            time:'2022-02-01',
            description: '法规条款解读',
            violation:'',
            basis:'/assets/video/first-class.mp4',
            unit: '',
            type:'',
            punishment:'',
            published:'2020-02-01',
        },
        {
            id: 6,
            name: '同意及偏好专业解读',
            industry: '个保法',
            enterprise:'',
            time:'2022-03-01',
            description: '助力企业数据安全合规',
            violation:'',
            basis:'/assets/video/cpm.mp4',
            unit: '',
            type:'',
            punishment:'',
            published:'2020-02-01',
        },
    ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return objects
// ------------------------------------------------
mock.onGet('/api/training-course').reply(config => {
    // eslint-disable-next-line object-curly-newline
    const {
        q = '',
        perPage = 10,
        page = 1,
        sortBy = 'id',
        sortDesc = false,
        status = null,
    } = config.params
    /* eslint-enable */

    const queryLowered = q.toLowerCase()
    const filteredData = data.list.filter(
        item =>
            /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
            (item.name.toLowerCase().includes(queryLowered) ||
                item.description.toLowerCase().includes(queryLowered) )
    )
    /* eslint-enable  */

    const sortedData = filteredData.sort(sortCompare(sortBy))
    if (sortDesc) sortedData.reverse()

    return [
        200,
        {
            success: 0,
            list: paginateArray(sortedData, perPage, page),
            total: filteredData.length,
        },
    ]
})

// ------------------------------------------------
// POST: Add new 
// ------------------------------------------------
mock.onPost('/api/training-course').reply(config => {
    // Get event from post data
    const { item } = JSON.parse(config.data)

    // Assign Status
    item.status = 'active'

    const { length } = data.list
    let lastIndex = 0
    if (length) {
        lastIndex = data.item[length - 1].id
    }
    item.id = lastIndex + 1

    data.list.push(item)

    return [201, { success: 0, item }]
})

// ------------------------------------------------
// PATCH: update
// ------------------------------------------------
mock.onPatch(/\/api\/training-course\/\d+/).reply(config => {
    // Get event id from URL
    let id = config.url.substring(config.url.lastIndexOf('/') + 1)

    // Get event from post data
    let { course } = JSON.parse(config.data)

    // Convert Id to number
    id = Number(id)

    const idx = data.list.findIndex(e => e.id === id)

    // UPDATE
    data.list.splice(idx, 1, course)
    course = data.list[idx]

    return [200, { success: 0, course }]
})

// ------------------------------------------------
// GET: Return Single 
// ------------------------------------------------
mock.onGet(/\/api\/training-course\/\d+/).reply(config => {
    // Get event id from URL
    let id = config.url.substring(config.url.lastIndexOf('/') + 1)

    // Convert Id to number
    id = Number(id)

    const idx = data.list.findIndex(e => e.id === id)
    const item = data.list[idx]

    if (item) return [200, { sucess: 0, item }]
    return [404]
})

// ------------------------------------------------
// GET: delete Single 
// ------------------------------------------------
mock.onDelete(/\/api\/training-course\/\d+/).reply(config => {
    // Get event id from URL
    let id = config.url.substring(config.url.lastIndexOf('/') + 1)

    // Convert Id to number
    id = Number(id)

    const idx = data.list.findIndex(e => e.id === id)
    data.list.splice(idx, 1);

    return [200, { sucess: 0 }]
})

