//import $http from './_http'
import $http from '@axios'

// get object.value by name from array list, convert it from string to json
const getObjectByName = (list, name) => {
    let result = list.find(item => item.name === name)
    return result ? JSON.parse(result.value) : null
}

export default {
    namespaced: true,

    state: {
        eid: 0,

        user: null,

        industryOptions: [],
        companyScaleOptions: [],
        complianceOptions: [],
    },

    getters: {
        eid: state => state.eid,

        user: state => state.user,

        industryOptions: state => {
            const { industryOptions } = state
            return industryOptions
        },

        industryOptionsFull: state => {
            const { industryOptions } = state
            return [{ value: "", text: "应用行业", disabled: true }].concat(industryOptions)
        },

        companyScaleOptions: state => {
            const { companyScaleOptions } = state
            return companyScaleOptions
        },

        companyScaleOptionsFull: state => {
            const { companyScaleOptions } = state
            return [{ value: 0, text: "企业规模", disabled: true }].concat(companyScaleOptions)
        },

        complianceOptions: state => {
            const { complianceOptions } = state
            return complianceOptions
        },

        complianceOptionsFull: state => {
            const { complianceOptions } = state
            return [{ value: "", text: "合规要求", disabled: true }].concat(complianceOptions)
        },
    },

    mutations: {
        UPDATE_OPTION(state, { key, val }) {
            if (state.hasOwnProperty(key)) {
                state[key] = val
            } else {
                console.error(`${key} is not a valid option key`)
            }
        },

        UPDATE_EID(state, eid) {
            state.eid = eid
        },

        UPDATE_USER(state, user) {
            state.user = user
        },
    },

    actions: {
        LOAD_EID({ commit }) {
            let userData = localStorage.getItem("userData")
            if (userData) {
                userData = JSON.parse(userData)
                commit("UPDATE_EID", userData.eid)
            }
        },

        LOAD_USER({ commit }) {
            let userData = localStorage.getItem("userData")
            if (userData) {
                userData = JSON.parse(userData)
                commit("UPDATE_USER", userData)
            }
        },

        FETCH_OPTIONS({ commit }) {
            // console.log("FETCH_OPTIONS")
            $http.get("/configdatas").then(res => {
                let list = res.data.data.list;
                const industryOptions = getObjectByName(list, "industryOptions")
                const companyScaleOptions = getObjectByName(list, "companyScaleOptions")
                const complianceOptions = getObjectByName(list, "complianceOptions")


                commit('UPDATE_OPTION', { key: 'industryOptions', val: industryOptions })
                commit('UPDATE_OPTION', { key: 'companyScaleOptions', val: companyScaleOptions })
                commit('UPDATE_OPTION', { key: 'complianceOptions', val: complianceOptions })
            }).catch(err => {
                console.error(err)
            })
        },
    },
}
