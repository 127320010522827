import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  roles: [
    {
      id: 1,
      name: '产品经理',
      associated: 5,
      rights: [
        {
          resource: '产品1',
          action: 'read',
        },
        {
          resource: '产品2',
          action: 'update',
        }]
    },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return list
// ------------------------------------------------
mock.onGet('/api/role').reply(config => {
  // // eslint-disable-next-line object-curly-newline
  // const {
  //   q = '',
  //   perPage = 10,
  //   page = 1,
  //   sortBy = 'id',
  //   sortDesc = false,
  //   role = null,
  //   plan = null,
  //   status = null,
  // } = config.params
  // /* eslint-enable */

  // const queryLowered = q.toLowerCase()
  // const filteredData = data.users.filter(
  //   user =>
  //     /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
  //     (user.username.toLowerCase().includes(queryLowered) || user.fullName.toLowerCase().includes(queryLowered)) &&
  //     user.role === (role || user.role) &&
  //     user.currentPlan === (plan || user.currentPlan) &&
  //     user.status === (status || user.status),
  // )
  // /* eslint-enable  */

  // const sortedData = filteredData.sort(sortCompare(sortBy))
  // if (sortDesc) sortedData.reverse()

  // return [
  //   200,
  //   {
  //     users: paginateArray(sortedData, perPage, page),
  //     total: filteredData.length,
  //   },
  // ]
  return [
    200,
    {
      roles: data.roles,
      total: data.roles.length,
    },
  ]
})
