import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  products: [
    {
      id: 100,
      entName: '深圳市科技发展有限公司',
      name: 'ERP业务系统',
      status: '已上线',
      version: '1.0.0',
      manager: {
        avatar: require('@/assets/images/avatars/1-small.png'),
        name: '王大纲',
        email: 'wangdg@sjijing.com',
      },
      pia: {
        status: '已认证',
      },
    },
    {
      id: 101,
      entName: '深圳市科技发展有限公司',
      name: '业务分析系统',
      status: '已上线',
      version: '2.0.0',
      manager: {
        avatar: require('@/assets/images/avatars/2-small.png'),
        name: '李伟',
        email: 'liw@sjijing.com',
      },
      pia: {
        status: '认证中',
      },
    },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return list
// ------------------------------------------------
mock.onGet('/api/product/list').reply(config => {
  // eslint-disable-next-line object-curly-newline
  // const { q = '', perPage = 10, page = 1, sortBy = 'id', sortDesc = false, status = null } = config.params
  /* eslint-enable */

  // const queryLowered = q.toLowerCase()
  // const filteredData = data.products.filter(
  //   item =>
  //     /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
  //     (item.client.companyEmail.toLowerCase().includes(queryLowered) ||
  //       item.client.name.toLowerCase().includes(queryLowered)) &&
  //     item.itemStatus === (status || item.itemStatus),
  // )
  /* eslint-enable  */

  // const sortedData = filteredData.sort(sortCompare(sortBy))
  // if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      // products: paginateArray(sortedData, perPage, page),
      products: data.products,
      total: data.products.length,
    },
  ]
})

// ------------------------------------------------
// GET: Return Single 
// ------------------------------------------------
mock.onGet(/\/api\/product\/\d+/).reply(config => {
  // Get event id from URL
  let id = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  id = Number(id)

  const index = data.products.findIndex(e => e.id === id)
  const item = data.products[index]
  const responseData = {
    item,
  }

  if (invoice) return [200, responseData]
  return [404]
})