import mock from '@/@fake-db/mock'
import jwt from 'jsonwebtoken'

const abilities = {
  // 系统管理员
  admin: [
    {
      action: 'manage',
      subject: ['home', 'quickStart', 'profile', 'Auth', 'header-1', 'header-2',],
    },
    {
      action: 'manage',
      subject: ['organization', 'user', 'role', 'email', 'notify', 'log',],
    },
    {
      action: 'manage',
      subject: ['library-law', 'library-case', 'library-survey', 'library-template', 'library-flow', 'library-dictionary',],
    },
  ],

  // 运营管理员
  operator: [
    {
      action: 'manage',
      subject: ['home', 'quickStart', 'profile', 'Auth', 'header-1', 'header-2',],
    },
    {
      action: 'manage',
      subject: ['enterprise', 'training', 'product', 'datamap', 'pia', 'cpm', 'cookies', 'statistics', 'largescreen', ],
    },
    {
      action: 'manage',
      subject: ['library-law', 'library-case', 'library-survey', 'library-template', 'library-flow', 'library-dictionary',],
    },
    {
      action: 'manage',
      subject: ['organization', 'user', 'role', 'email', 'notify', 'log',],
    },
  ],

  // 法务管理员
  counselor: [
    {
      action: 'manage',
      subject: ['home', 'quickStart', 'profile', 'Auth', 'header-1', 'header-2',],
    },
    {
      action: 'manage',
      subject: ['product', 'datamap', 'pia', 'consent',],
    },
    {
      action: 'manage',
      subject: ['library-law', 'library-case', 'library-survey', 'library-template', 'library-flow', 'library-dictionary',],
    },
  ],

  // 监管机构
  supervisor: [
    {
      action: 'view',
      subject: ['statistics', 'largescreen', 'Auth',],
    },
  ],
}
const data = {
  users: [
    {
      id: 1,
      fullName: '系统管理员',
      name: 'admin',
      password: 'Picaas.2022',
      // eslint-disable-next-line global-require
      avatar: require('@/assets/images/avatars/1-small.png'),
      email: 'admin@demo.com',
      role: 'admin',
      ability: abilities.admin,
      extras: {
        eCommerceCartItemsCount: 5,
      },
    },
    {
      id: 2,
      fullName: '运营管理员',
      username: 'operator',
      password: 'Picaas.2022',
      // eslint-disable-next-line global-require
      avatar: require('@/assets/images/avatars/1-small.png'),
      email: 'operator@demo.com',
      role: 'operator',
      ability: abilities.operator,
      extras: {
        eCommerceCartItemsCount: 5,
      },
    },
    {
      id: 3,
      fullName: '法律管理员',
      username: 'counselor',
      password: 'Picaas.2022',
      // eslint-disable-next-line global-require
      avatar: require('@/assets/images/avatars/1-small.png'),
      email: 'counselor@demo.com',
      role: 'counselor',
      ability: abilities.counselor,
      extras: {
        eCommerceCartItemsCount: 5,
      },
    },
    {
      id: 4,
      fullName: '监管机构',
      username: 'supervisor',
      password: 'Picaas.2022',
      // eslint-disable-next-line global-require
      avatar: require('@/assets/images/avatars/1-small.png'),
      email: 'supervisor@demo.com',
      role: 'supervisor',
      ability: abilities.counselor,
      extras: {
        eCommerceCartItemsCount: 5,
      },
    },
  ],
}

// ! These two secrets shall be in .env file and not in any other file
const jwtConfig = {
  secret: 'dd5f3089-40c3-403d-af14-d0c228b05cb4',
  refreshTokenSecret: '7c4c1c50-3230-45bf-9eae-c9b2e401c767',
  expireTime: '10m',
  refreshTokenExpireTime: '10m',
}

mock.onPost('/api/login').reply(request => {
  const { email, password } = JSON.parse(request.data)

  let error = {
    email: ['Something went wrong'],
  }

  const user = data.users.find(u => u.email === email && u.password === password)

  if (user) {
    try {
      const accessToken = jwt.sign({ id: user.id }, jwtConfig.secret, { expiresIn: jwtConfig.expireTime })
      const refreshToken = jwt.sign({ id: user.id }, jwtConfig.refreshTokenSecret, {
        expiresIn: jwtConfig.refreshTokenExpireTime,
      })

      const userData = { ...user }

      delete userData.password

      const response = {
        userData,
        accessToken,
        refreshToken,
      }

      return [200, response]
    } catch (e) {
      error = e
    }
  } else {
    error = {
      email: ['Email or Password is Invalid'],
    }
  }

  return [400, { error }]
})

mock.onPost('/api/refresh-token').reply(request => {
  const { refreshToken } = JSON.parse(request.data)

  try {
    const { id } = jwt.verify(refreshToken, jwtConfig.refreshTokenSecret)

    const userData = { ...data.users.find(user => user.id === id) }

    const newAccessToken = jwt.sign({ id: userData.id }, jwtConfig.secret, { expiresIn: jwtConfig.expiresIn })
    const newRefreshToken = jwt.sign({ id: userData.id }, jwtConfig.refreshTokenSecret, {
      expiresIn: jwtConfig.refreshTokenExpireTime,
    })

    delete userData.password
    const response = {
      userData,
      accessToken: newAccessToken,
      refreshToken: newRefreshToken,
    }

    return [200, response]
  } catch (e) {
    const error = 'Invalid refresh token'
    return [401, { error }]
  }
})
