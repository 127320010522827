import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  organizations: [
    {
      id: 1,
      name: '产品研发部',
      pid: 0,
      createdAt: '2022-1-1T08:05:00.000+08.000',
      createdBy: '',
      status: 'active',
    },
    {
      id: 2,
      name: '产品经理',
      pid: 1,
      createdAt: '2022-1-1T08:05:00.000+08.000',
      createdBy: '',
      status: 'active',
    },
    {
      id: 3,
      name: 'UI设计',
      pid: 1,
      createdAt: '2022-1-1T08:05:00.000+08.000',
      createdBy: '',
      status: 'active',
    },
    
    {
      id: 4,
      name: '销售部',
      pid: 0,
      createdAt: '2022-1-1T08:05:00.000+08.000',
      createdBy: '',
      status: 'active',
    },
    {
      id: 5,
      name: '销售一部',
      pid: 4,
      createdAt: '2022-1-1T08:05:00.000+08.000',
      createdBy: '',
      status: 'active',
    },
    {
      id: 6,
      name: '销售二部',
      pid: 4,
      createdAt: '2022-1-1T08:05:00.000+08.000',
      createdBy: '',
      status: 'active',
    },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return 
// ------------------------------------------------
mock.onGet('/api/organization').reply(config => {
  
  return [
    200,
    {
      success: 0,      
      organizations: data.organizations,
      total: data.organizations.length,
    },
  ]
})

// ------------------------------------------------
// POST: Add new 
// ------------------------------------------------
mock.onPost('/api/organization').reply(config => {
  // Get event from post data
  const { enterprise } = JSON.parse(config.data)

  // Assign Status
  enterprise.status = 'active'

  const { length } = data.organizations
  let lastIndex = 0
  if (length) {
    lastIndex = data.enterprise[length - 1].id
  }
  enterprise.id = lastIndex + 1

  data.organizations.push(enterprise)

  return [201, { success: 0, enterprise }]
})

// ------------------------------------------------
// PATCH: update
// ------------------------------------------------
mock.onPatch(/\/api\/organization\/\d+/).reply(config => {
  // Get event id from URL
  let id = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Get event from post data
  let { enterprise } = JSON.parse(config.data)

  // Convert Id to number
  id = Number(id)

  const idx = data.organizations.findIndex(e => e.id === id)

  // UPDATE
  data.organizations.splice(idx, 1, enterprise)
  enterprise = data.organizations[idx]

  return [200, { success: 0, enterprise }]
})

// ------------------------------------------------
// GET: Return Single 
// ------------------------------------------------
mock.onGet(/\/api\/organization\/\d+/).reply(config => {
  // Get event id from URL
  let id = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  id = Number(id)

  const idx = data.organizations.findIndex(e => e.id === id)
  const enterprise = data.organizations[idx]

  if (enterprise) return [200, {sucess:0, enterprise}]
  return [404]
})

// ------------------------------------------------
// GET: delete Single 
// ------------------------------------------------
mock.onDelete(/\/api\/organization\/\d+/).reply(config => {
  // Get event id from URL
  let id = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  id = Number(id)

  const idx = data.organizations.findIndex(e => e.id === id)
  data.organizations.splice(idx, 1);

  return [200, {sucess:0}]
})

