export default [
  {
    path: '/cookies-template',
    name: 'cookies-template',
    component: () => import('@/views/cookies/Cookies.vue'),
    meta: {
      resource: 'cookies',
      pageTitle: 'Cookie管理',
      breadcrumb: [
        {
          text: '模版管理',
          active: true,
        },
      ],
    },
  },

  {
    path: '/cookies-website',
    name: 'cookies-website',
    component: () => import('@/views/cookies/Cookies.vue'),
    meta: {
      resource: 'cookies',
      pageTitle: 'Cookie管理',
      breadcrumb: [
        {
          text: '网站配置',
          active: true,
        },
      ],
    },
  },

]