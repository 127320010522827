import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */

// select options
const data = {
  industry: [
    { value: "", text: "应用行业" },
    "互联网",
    "车联网",
    "商业",
    "贸易",
    "制造业",
    "农业",
    "医疗",
    "物流",
  ],
  compliance: [
    { value: "", text: "合规要求" },
    "GDPR",
    "CCPA",
    "PIPL",
  ],
  scale: ["0-10", "10-100", "100-1000", ">1000"],

}

// get dictionary list
mock.onGet(/\/api\/dictionary\/\w+/).reply(config => {
  let id = config.url.substring(config.url.lastIndexOf('/') + 1)
  let list = data[id];

  return [
    200,
    {
      success: 0,
      list,
      total: list.length,
    },
  ]
})
