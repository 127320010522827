import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
    list: [
        {
            id: 1,
            code: 'S01',
            title: '网站隐私声明',
            overview: '公司主站的隐私声明',
            content: '',
            apply: ['Website'],
            type: '法律法规',
            attachment: '/assets/pdf/google_terms_of_service_zh-CN.pdf',
            status: "active",
            published: '2022-01-01',
        },
        {
            id: 2,
            code: 'S02',
            title: '安卓App隐私声明',
            overview: '安卓App隐私声明',
            content: '',
            apply: ['App', 'Android'],
            type: '法律法规',
            attachment: '/assets/pdf/手机银行App隐私声明.pdf',
            status: "active",
            published: '2022-01-01',
        },
        {
            id: 3,
            code: 'S03',
            title: 'iOS App隐私声明',
            overview: 'iOS App隐私声明',
            content: '',
            apply: ['App', 'iOS'],
            type: '法律法规',
            attachment: '/assets/pdf/iOS_APP声明模板.pdf',
            status: "active",
            published: '2022-01-01',
        },
        {
            id: 4,
            code: 'S04',
            title: '微信小程序隐私声明',
            overview: '微信小程序隐私声明',
            content: '',
            apply: ['微信', '小程序'],
            type: '法律法规',
            attachment: '/assets/pdf/手机银行App隐私声明.pdf',
            status: "active",
            published: '2022-01-01',
        },
    ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return objects
// ------------------------------------------------
mock.onGet('/api/enterprise-statement').reply(config => {
    // eslint-disable-next-line object-curly-newline
    const {
        apply = '',
        q = '',
        perPage = 10,
        page = 1,
        sortBy = 'id',
        sortDesc = false,
        status = null,
    } = config.params
    /* eslint-enable */

    const queryLowered = q.toLowerCase()
    const filteredData = data.list.filter(
        item =>
            /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
            (item.title.toLowerCase().includes(queryLowered) ||
                item.content.toLowerCase().includes(queryLowered) &&
            item.apply.includes(apply) )
    )
    /* eslint-enable  */

    const sortedData = filteredData.sort(sortCompare(sortBy))
    if (sortDesc) sortedData.reverse()

    return [
        200,
        {
            success: 0,
            list: paginateArray(sortedData, perPage, page),
            total: filteredData.length,
        },
    ]
})

// ------------------------------------------------
// POST: Add new 
// ------------------------------------------------
mock.onPost('/api/enterprise-statement').reply(config => {
    // Get event from post data
    const { item } = JSON.parse(config.data)

    // Assign Status
    item.status = 'active'

    const { length } = data.list
    let lastIndex = 0
    if (length) {
        lastIndex = data.item[length - 1].id
    }
    item.id = lastIndex + 1

    data.list.push(item)

    return [201, { success: 0, item }]
})

// ------------------------------------------------
// PATCH: update
// ------------------------------------------------
mock.onPatch(/\/api\/enterprise-statement\/\d+/).reply(config => {
    // Get event id from URL
    let id = config.url.substring(config.url.lastIndexOf('/') + 1)

    // Get event from post data
    let { item } = JSON.parse(config.data)

    // Convert Id to number
    id = Number(id)

    const idx = data.list.findIndex(e => e.id === id)

    // UPDATE
    data.list.splice(idx, 1, item)
    item = data.list[idx]

    return [200, { success: 0, item }]
})

// ------------------------------------------------
// GET: Return Single 
// ------------------------------------------------
mock.onGet(/\/api\/enterprise-statement\/\d+/).reply(config => {
    // Get event id from URL
    let id = config.url.substring(config.url.lastIndexOf('/') + 1)

    // Convert Id to number
    id = Number(id)

    const idx = data.list.findIndex(e => e.id === id)
    const item = data.list[idx]

    if (item) return [200, { sucess: 0, item }]
    return [404]
})

// ------------------------------------------------
// GET: delete Single 
// ------------------------------------------------
mock.onDelete(/\/api\/enterprise-statement\/\d+/).reply(config => {
    // Get event id from URL
    let id = config.url.substring(config.url.lastIndexOf('/') + 1)

    // Convert Id to number
    id = Number(id)

    const idx = data.list.findIndex(e => e.id === id)
    data.list.splice(idx, 1);

    return [200, { sucess: 0 }]
})

