export default [
  {
    path: '/product',
    name: 'product',
    component: () => import('@/views/product/Product.vue'),
    meta: {
      resource: 'product',
      pageTitle: '产品管理',
      breadcrumb: [
        {
          text: '产品管理',
          active: true,
        },
      ],
    },
  },
]