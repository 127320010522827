export default [
  {
    path: '/pia-analysis',
    name: 'pia-analysis',
    component: () => import('@/views/pia/PIA.vue'),
    meta: {
      resource: 'pia',
      pageTitle: '隐私评估',
      breadcrumb: [
        {
          text: 'PIA报表管理',
          active: true,
        },
      ],
    },
  },

  {
    path: '/pia-dictionary',
    name: 'pia-dictionary',
    component: () => import('@/views/pia/dic/DataDictionary.vue'),
    meta: {
      resource: 'pia',
      pageTitle: '隐私评估',
      breadcrumb: [
        {
          text: 'PIA数据字典',
          active: true,
        },
      ],
    },
  },

  {
    path: '/pia-process',
    name: 'pia-process',
    component: () => import('@/views/pia/progress/PiaProcess.vue'),
    meta: {
      resource: 'pia',
      pageTitle: '隐私评估',
      breadcrumb: [
        {
          text: 'PIA启动管理',
          active: true,
        },
      ],
    },
  },

  {
    path: '/pia-process/:id',
    name: 'pia-process-edit',
    component: () => import('@/views/pia/progress/PiaProcessEdit.vue'),
    meta: {
      resource: 'pia',
      pageTitle: '隐私评估',
      breadcrumb: [
        {
          text: 'PIA流程列表',
          to: { path: '/pia-process' },
        },
        {
          text: 'PIA流程编辑',
          active: true,
        },
      ],
    },
  },

  {
    path: '/pia-form',
    name: 'pia-form',
    component: () => import('@/views/pia/PiaForm.vue'),
    meta: {
      resource: 'pia',
      pageTitle: '隐私评估',
      breadcrumb: [
        {
          text: 'PIA填报',
          active: true,
        },
      ],
    },
  },

]