export default [
  {
    path: '/datamap-fields',
    name: 'datamap-fields',
    component: () => import('@/views/datamap/fields/PrivacyFields.vue'),
    meta: {
      resource: 'datamap',
      pageTitle: '数据地图',
      breadcrumb: [
        {
          text: '隐私字段清单',
          active: true,
        },
      ],
    },
  },
  {
    path: '/datamap-asset',
    name: 'datamap-asset',
    component: () => import('@/views/datamap/asset/AssetList.vue'),
    meta: {
      resource: 'datamap',
      pageTitle: '数据资产',
      breadcrumb: [
        {
          text: '数据资产清单',
          active: true,
        },
      ],
    },
  },
  {
    path: '/datamap-analysis',
    name: 'datamap-analysis',
    component: () => import('@/views/datamap/DataMapAnalysis.vue'),
    meta: {
      resource: 'datamap',
      pageTitle: '数据地图',
      breadcrumb: [
        {
          text: '数据地图分析',
          active: true,
        },
      ],
    },
  },
  {
    path: '/datamap-process',
    name: 'datamap-process',
    component: () => import('@/views/datamap/progress/DataMapProcess.vue'),
    meta: {
      resource: 'datamap',
      pageTitle: '数据地图',
      breadcrumb: [
        {
          text: '调研流程列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/datamap-process/:id',
    name: 'datamap-process-edit',
    component: () => import('@/views/datamap/progress/DataMapProcessEdit.vue'),
    meta: {
      resource: 'datamap',
      pageTitle: '数据地图',
      breadcrumb: [
        {
          text: '调研流程列表',
          to: { path: '/datamap-process' },
        },
        {
          text: '调研流程编辑',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:eid/datamap-file/:surveyInstanceId',
    name: 'datamap-form',
    component: () => import('@/views/datamap/DataMapForm.vue'),
    meta: {
      resource: 'datamap',
      pageTitle: '数据地图',
      breadcrumb: [
        {
          text: '调研流程列表',
          to: { path: '/datamap-process' },
        },
        {
          text: '调研表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:eid/datamap-file',
    name: 'datamap-form',
    component: () => import('@/views/datamap/DataMapForm.vue'),
    meta: {
      resource: 'datamap',
      pageTitle: '数据地图',
      breadcrumb: [
        {
          text: '调研流程列表',
          to: { path: '/datamap-process' },
        },
        {
          text: '调研表',
          active: true,
        },
      ],
    },
  },

]
