import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

import enterprise from './routes/enterprise'
import product from './routes/product'
import datamap from './routes/datamap'
import pia from './routes/pia'
import consent from './routes/consent'
import course from './routes/course'
import template from './routes/template'
import settings from './routes/settings'
import pages from './routes/pages'
import profile from './routes/profile'
import library from './routes/library'
import cookies from './routes/cookies'
import cpm from './routes/cpm'
import statistics from './routes/statistics'
import training from './routes/training'
import compliance from './routes/compliance'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...enterprise,
    ...product,
    ...datamap,
    ...pia,
    ...consent,
    ...course,
    ...template,
    ...settings,
    ...pages,
    ...profile,    
    ...cookies,
    ...cpm,
    ...library,
    ...statistics,
    ...training,
    ...compliance,
  ],
})

router.beforeEach((to, _, next) => {
  
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
