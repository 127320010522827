export default [
  {
    path: '/settings/organization',
    name: 'organization',
    component: () => import('@/views/organization/Organization.vue'),
    meta: {
      resource: 'organization',
      pageTitle: '系统配置',
      breadcrumb: [
        {
          text: '部门管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/user',
    name: 'user',
    component: () => import('@/views/user/User.vue'),
    meta: {
      resource: 'user',
      pageTitle: '系统配置',
      breadcrumb: [
        {
          text: '用户管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/user/:id',
    name: 'user-edit',
    component: () => import('@/views/user/UserEdit.vue'),
    meta: {
      resource: 'user',
      pageTitle: '系统配置',
      breadcrumb: [
        {
          text: '用户管理',
          to: {name:'user'},
        },
        {
          text: '用户编辑',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/resource',
    name: 'resource',
    component: () => import('@/views/resource/Resource.vue'),
    meta: {
      resource: 'resource',
      pageTitle: '系统配置',
      breadcrumb: [
        {
          text: '资源管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/role',
    name: 'role',
    component: () => import('@/views/role/Role.vue'),
    meta: {
      resource: 'role',
      pageTitle: '系统配置',
      breadcrumb: [
        {
          text: '角色管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/role/:id',
    name: 'role-edit',
    component: () => import('@/views/role/RoleEdit.vue'),
    meta: {
      resource: 'role',
      pageTitle: '系统配置',
      breadcrumb: [
        {
          text: '角色管理',
          to: {name:'role'},
        },
        {
          text: '角色编辑',
          active: true,
        },
      ],
    },
  },

  {
    path: '/settings/smtp',
    name: 'smtp',
    component: () => import('@/views/email/SMTPSetting.vue'),
    meta: {
      resource: 'email',
      pageTitle: '系统配置',
      breadcrumb: [
        {
          text: '邮件服务器配置',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/notify',
    name: 'notify',
    component: () => import('@/views/notify/Notify.vue'),
    meta: {
      resource: 'notify',
      pageTitle: '系统配置',
      breadcrumb: [
        {
          text: '通知管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/log',
    name: 'log',
    component: () => import('@/views/log/LogList.vue'),
    meta: {
      resource: 'log',
      pageTitle: '系统配置',
      breadcrumb: [
        {
          text: '日志查看',
          active: true,
        },
      ],
    },
  },

]