import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  cpms: [
    {      
      id: 1987,
      entName: '深圳市唯一科技',
      totalfields: '20个',
      basicinfo: '15个',
      identity: '3个',
      healthy: '2个',
      recognization: '1个',
    },
    {
      id: 1988,
      entName: '深圳市蓝汛科技',
      totalfields: '50个',
      basicinfo: '35个',
      identity: '5个',
      healthy: '5个',
      recognization: '5个',
    },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return list
// ------------------------------------------------
mock.onGet('/api/cpm/list').reply(config => {
  // eslint-disable-next-line object-curly-newline
  // const { q = '', perPage = 10, page = 1, sortBy = 'id', sortDesc = false, status = null } = config.params
  /* eslint-enable */

  // const queryLowered = q.toLowerCase()
  // const filteredData = data.cpms.filter(
  //   item =>
  //     /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
  //     (item.client.companyEmail.toLowerCase().includes(queryLowered) ||
  //       item.client.name.toLowerCase().includes(queryLowered)) &&
  //     item.itemStatus === (status || item.itemStatus),
  // )
  /* eslint-enable  */

  // const sortedData = filteredData.sort(sortCompare(sortBy))
  // if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      // cpms: paginateArray(sortedData, perPage, page),
      cpms: data.cpms,
      total: data.cpms.length,
    },
  ]
})

// ------------------------------------------------
// GET: Return Single 
// ------------------------------------------------
mock.onGet(/\/api\/cpm\/\d+/).reply(config => {
  // Get event id from URL
  let id = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  id = Number(id)

  const index = data.cpms.findIndex(e => e.id === id)
  const item = data.cpms[index]
  const responseData = {
    item,
  }

  if (invoice) return [200, responseData]
  return [404]
})