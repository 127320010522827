export default [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/Home.vue'),
        meta: {
            pageTitle: 'Home',
            resource: 'home',
            action: 'read',
            breadcrumb: [
                {
                    text: 'Home',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/quick-start',
        name: 'quick-start',
        component: () => import('@/views/QuickStart.vue'),
        meta: {
            pageTitle: 'Quick Start',
            resource: 'quickStart',
            action: 'read',
            breadcrumb: [
                {
                    text: 'Home',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/login',
        name: 'auth-login',
        component: () => import('@/views/Login.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
    },
    {
      path: '/not-authorized',
      name: 'not-authorized',
      component: () => import('@/views/error/NotAuthorized.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Auth',
      },
    },
    {
        path: '/error-404',
        name: 'error-404',
        component: () => import('@/views/error/Error404.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '*',
        redirect: 'error-404',
    },

]