export default [
  {
    path: '/training-course',
    name: 'training-course',
    component: () => import('@/views/training/course/TrainingCourse.vue'),
    meta: {
      resource: 'training',
      pageTitle: '合规培训',
      breadcrumb: [
        {
          text: '培训课程',
          active: true,
        },
      ],
    },
  },
  {
    path: '/training-laws',
    name: 'training-laws',
    component: () => import('@/views/training/Laws.vue'),
    meta: {
      resource: 'training',
      pageTitle: '合规培训',
      breadcrumb: [
        {
          text: '法律法规',
          active: true,
        },
      ],
    },
  },
  {
    path: '/training-cases',
    name: 'training-cases',
    component: () => import('@/views/training/Cases.vue'),
    meta: {
      resource: 'training',
      pageTitle: '合规培训',
      breadcrumb: [
        {
          text: '经典案例',
          active: true,
        },
      ],
    },
  },

]