export default [
  {
    path: '/course',
    name: 'course',
    component: () => import('@/views/course/Course.vue'),
    meta: {
      pageTitle: '知识库',
      breadcrumb: [
        {
          text: '知识库',
          active: true,
        },
      ],
    },
  },

]