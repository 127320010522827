export default [
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/profile/AccountSetting.vue'),
    meta: {
      pageTitle: '账号设置',
      breadcrumb: [
        {
          text: '个人信息',
          active: true,
        },
      ],
      resource: 'profile',
      action: 'manage',
    },
  },

]